@import '~bootstrap/scss/bootstrap';

:root {
  --page_background_image: url('../public/page-bg.png');
  --auth_background_image: url('../public/login-bg.png');
  --bs-border-radius: 16px !important;
}

body {
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  background-image: var(--page_background_image);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

h1 {
  font-weight: 300;
  font-size: 28px;
}

.authBody {
  background-image: var(--auth_background_image) !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.6rem;
}

.card-header {
  font-size: 22px;
  font-weight: 700;
  color: #202020;
}

.navbar {
  min-height: 72px;
}

.show-password {
  position: absolute;
  right: 64px;
  margin-top: -38px;
  font-size: 20px;
  color: #717171;
  cursor: pointer;
}

.logo {
  background-image: url('../public/nsu-logo.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  width: 273px;
  height: 94px;
}

.logo-small {
  background-image: url('../public/nsu-logo.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 140px;
  height: 49px;
  margin: 0px;
  position: absolute;
  left: 32px;
  top: 12px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.6rem;
}

.content-right {
  display: flex;
  justify-content: right;
  align-items: right;
  margin: 1.6rem;
  margin-right: 0px;
}

.btn-nsu {
  border: none;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 35px;
  min-height: 45px;
  width: 100%;
}

.btn-middle-nsu {
  border: none;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 35px;
  min-height: 45px;
  width: auto;
}

.btn-none-nsu {
  border: none;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  min-height: 45px;
  width: auto;
  color: #202020;
  background-color: transparent !important;

  &:hover {
    color: #F2505D;
    background-color: transparent !important;
  }
}

.color-danger {
  background-color: #F2505D;
  border: none !important;

  &:hover {
    background-color: #E34A52;
  }

  &:disabled {
    background-color: #f4babf;
    color: #FFFFFF;
  }
}

.color-default {
  background-color: #FFFFFF;
  border: solid 1px #F2505D;
  color: #F2505D;

  &:hover {
    background-color: #F2F2F2;
    border: solid 1px #F2505D;
    color: #F2505D;
  }
}

.form-label {
  color: #6A6F7D;
  font-size: 16px;
}

.form-check-label {
  color: #969AA8;
  font-size: 12px;
  font-weight: 400;
}

.nsu-form-control {
  border: none;
  border-radius: 0px;
  border-bottom: solid 2px #156CF7;
  padding-left: 0px;
  font-size: 16px !important;
}

.sub-title {
  padding: 1rem;
}

.top-min-margin {
  margin-top: 1.6rem;
}

.top-max-margin {
  margin-top: 54px;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fa;
  padding: 1rem;
  text-align: center;
}

.login {
  background-color: #D9D9D9;
  border: none;
  border-radius: 35px;
  padding: 32px;
  text-align: center;

  h1 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 32px;
  }
}

.nsu-form-control {
  height: 60px;
  border: solid 1px #c1c1c1;
  border-radius: 15px;
  background-color: #fff;
  color: #202020;
  font-size: 16px;
  padding-left: 16px;

  &::placeholder {
    color: #c1c1c1;
    font-size: 16px;  
  }
}

.centered-text-input {
  text-align: center;
}

.divider-line {
  height: 1px;
  width: 100%;
  background-color: #F6F6F6;
  margin-top: 64px;
}

.divider-text {
  color: #969AA8;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-top: 54px;
}

.form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #F2505D !important;
}

.form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #202020 !important;
  font-size: 16px;
  font-weight: 400;
}

.sub-navigation {
  margin: 0px;
  padding: 0px;

  li {
    list-style: none;
    margin: 0px;
    padding: 0px;
    padding-bottom: 32px;

    a {
      font-size: 16px;
      font-weight: 600;
      color: #202020;
      text-decoration: none;
    }

    .active-item {
      color: #F2505D;
    }
  }

  .fa-chevron-right {
    font-size: 12px;
    color: #202020;
    float: right;
    margin-top: 8px;
  }
}

.dropdown-toggle {
  background-color: transparent;
  width: 48px;
  height: 48px;
  border-radius: 90px;
  border: solid 4px #F2505D;
  color: #F2505D;

  &:hover {
    background-color: #F2505D;
    color: #FFFFFF;
    border: solid 4px #FFFFFF;
  }
}

.justify-content-end {
  position: absolute;
  right: 34px;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  left: -120px !important;
  top: 58px !important;
}

.dropdown-item {
  color: #FFFFFF;

  &:hover {
    color: #F2505D;
  }
}

.navbar-text {
  font-size: 18;
  font-weight: 600;
  color: #FFFFFF;
  margin-right: 32px;
}

.header-email {
  display: block;
  font-size: 10px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: right;
}

.profile {
  border-radius: 12px;
}

.tickets {
  border-radius: 34px;
}

.tab-card {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.dashboard-box {
  border-radius: 12px;
  cursor: pointer;
  width: 300px;
  height: 300px;
  text-align: center;
  padding-top: 70px;
  transition: background-color 300ms ease-out 100ms;

  .dashboard-icon {
    font-size: 72px;
    color: #F2505D;
  }
  
  h2 {
    font-size: 24px;
    font-weight: 800;
    color: #202020;
    margin-top: 16px;
  }

  .dashboard-over {
    background-color: #F2505D;
    width: 300px;
    height: 44px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-left: -1px;
    margin-bottom: -1px;
    border: none !important;
  }

  &:hover {
    background-color: #F2505D;

    .dashboard-icon {
      color: #ffffff;
    }

    h2 {
      color: white;
    }
  }
}

.productLocation {
  color: #717171;
}

.paymentTitle {
  color: #202020;
}

.success {
  color: #4caf50;
}

.failed {
  color: #F2505D;
}

.paymentInfoText {
  font-size: 16px;
  font-weight: 400;
  color: #717171;
  display: inline-block;
}

.scrollableVertical {
  overflow-y: auto;
}

.rightSpaced {
  margin-right: 16px;
}

.cart-number {
  font-size: 12px;
  position: absolute;
  margin-top: 24px;
}

.field-editor {
  border-top: solid 1px #dddddd;
  width: 100%;
  padding: 16px;
  padding-right: 0px;
  font-size: 16px !important;

  label {
    font-size: 16px !important;
    font-weight: 400;
    display: block;
    margin-bottom: 16px;
    color: #6a6f7d;
  }

  span {
    font-size: 16px !important;
    font-weight: 400;
    color: #898989;
  }
}

.fa-shield-virus {
  font-size: 52px;
  color: #70B6C1;
}

.btn-inline-nsu {
  background-color: #FFFFFF;
  color: #F2505D;
  border: solid 1px #F2505D;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    background-color: #F2505D;
    color: #FFFFFF;
    border: solid 1px #F2505D;
  }
}

.text-align-right {
  text-align: right;
}

.top-spaced {
  padding-top: 64px;
}

.place {
  cursor: pointer;
}

.card-img-top {
  min-height: 115px;
  max-height: 115px;
  height: 115px;
  width: 100%;
  opacity: 0.8;
  border-radius: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  image-rendering: auto;

  &:hover {
    opacity: 1;
  }
}

h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  color: #000000;
  margin: 0px;
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 0px;
}

h4 {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin: 0px;
  margin-bottom: 16px;
  margin-top: 16px;
}

p {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding: 0px;
  margin: 0px;
}

.place-card {
  border: none !important;

  .card-body {
    padding: 4px;
  }
}

.card-text {
  font-size: 12px;
  color: #717171;
  font-weight: 400;
}

.info-panel {
  height: 100vh;
  min-height: 100vh;
}

.products-panel {
  height: 100vh;
  min-height: 100vh;
}

.accordion-button:not(.collapsed), .accordion-button:focus {
  background-color: transparent;
  box-shadow: none;
}

.product-qty {
  font-size: 22px;
}

.product-price {
  font-weight: 600;
}

.centered {
  text-align: center;
}

.right-position {
  text-align: right;
}

.large-padding {
  padding: 32px;
  padding-top: 32px;
}

.large-padding-header {
  padding: 32px;
  padding-bottom: 32px;
  border: none;
}

.accordion-button {
  font-size: 16px;
  font-weight: 700;
}

.cart-btn {
  width: 60px;
  height: 60px;
  border-radius: 90%;
  background-color: #D9D9D9;
  border: solid 4px #F2505D;
  color: #F2505D;
  text-align: center;
  font-size: 24px;
  padding-top: 8px;
  position: fixed;
  bottom: 40px;
  right: 16px;
  cursor: pointer;
  z-index: 99;

  &:hover {
    background-color: #F2505D;
    color: #FFFFFF;
    border: solid 4px #FFFFFF;
  }
}

.sum-price {
  text-align: right;
}

.modal-right {
  position: fixed;
  top: 0px;
  right: 0;
  margin: 0;
  height: 100%;
  max-height: 100%;
  width: 30%;
  transform: translateY(100%);
  transition: transform 0.2s ease-out;
}

.modal-right.show {
  transform: translateY(0);
}

.modal-content {
  height: 100%;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.link {
  font-size: 14px;
  font-weight: 400;
  color: #F2505D;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  left: 16px;

  &:hover {
    color: #F2505D;
  }
}

.trash {
  left: unset;
  right: 16px;
}

.cart-item {
  font-size: 14px;
  color: #000000;
  font-weight: 600;

  small {
    font-size: 14px;
    color: #717171;
    font-weight: 400;
  }

  span {
    float: right;
  }
}

.top-space {
  margin-top: 56px;
}

.input-repeater {
  margin-top: 16px;
}

.nav-link {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.nav-link-active {
  color: #717171;
}

.tickets-number {
  font-size: 32px;
  font-weight: 700;
  color: #F2505D;
}

.tickets-title {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-right: 16px;
}

.ticket-location {
  font-size: 16px;
  color: #717171 !important;
  font-weight: 400;
}

.ticket-name {
  font-size: 16px;
  font-weight: 600;
  color: #000000 !important;
}

.ticket-date {
  font-size: 14px;
  font-weight: 600;
  color: #F2505D !important;
}

.gallery-container {
  border-top: none;
  background-color: #062F4D;
  padding-bottom: 16px;
  padding-top: 32px;
}

.gallery-image {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
}

.bottom-spaced {
  margin-bottom: 32px;
}

.bottom-thin-spaced {
  margin-bottom: 8px;
}

.bordered {
  border: solid 1px #F2505D !important;
  border-radius: 24px;
  margin-right: 16px;
}

.gallery-image-fullscreen {
  width: 100%;
}

.subtitle {
  display: block;
  font-size: 22px;
  font-weight: 300;
  color: #202020;
}

.calendar-year {
  display: block;
  font-size: 22px;
  font-weight: 600;
  color: #202020;
}

.calendar-month {
  display: block;
  font-size: 22px;
  font-weight: 300;
  color: #202020;
}

.calendar-week {
  width: 100%;

  td {
    cursor: pointer;
    width: 10%;
    text-align: center;
  }

  .calendar-dayname {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #AAB4AD;
    padding-bottom: 12px;
  }

  .calendar-day {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #202020;
  }

  .active-day {
    border-radius: 12px;
    background-color: #F2505D;

    .calendar-dayname {
      color: #ffffff;
    }
  
    .calendar-day {
      color: #ffffff;
    }  
  }  
}

.bottom-margin {
  margin-bottom: 16px;
}

.reservations {
}

.places {
}

.top-padding {
  padding-top: 32px;
}

.chart-container {
  overflow-x: auto;

  .chart {
    display: flex;
    align-items: flex-end;
    gap: 20px;
  }
  .bar {
    width: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .segment {
    width: 100%;
    margin-bottom: 0px;
    border-radius: 8px;
  }
  .green {
    background-color: #4caf50;
    height: 10px;
    margin-top: -10px;
  }
  .orange {
    background-color: #ff9800;
    height: 30px;
    margin-top: -30px;
  }
  .red {
    background-color: #f44336;
    height: 50px;
    margin-top: -50px;
  }
  .gray {
    background-color: #bdbdbd;
    height: 100px;
  }
  .time-label {
    margin-top: 16px;
    font-size: 10px;
    color: #979797;
  }

  .ruler {
    width: 100%;
    height: 1px;
    background-color: #979797;
    margin-top: -25px;
    margin-bottom: 25px;
  }
}

.table-label {
  width: 100px;
}

.table-value {
  width: auto;
}

.label-location {
  font-size: 20px;
  font-weight: 400;
  color: #717171;
  margin: 0px;
  padding: 0px;
}

.label-type {
  font-size: 24px;
  font-weight: 600;
  color: #202020;
  margin: 0px;
  padding: 0px;
}

.label-expiration {
  font-size: 16px;
  font-weight: 300;
  color: #202020;
  margin: 0px;
  margin-bottom: 8px;
  padding: 0px;
}

.label-invoice {
  font-size: 14px;
  font-weight: 300;
  color: #717171;
  margin: 0px;
  margin-bottom: 16px;
  padding: 0px;
}

.ticket-frame {
  margin-top: 32px;
  margin-bottom: 32px;
}

.label-info {
  font-size: 11px;
  color: #717171;
  font-weight: 300;
  margin-top: 16px;
}

.language-selector {
  color: #F2505D;
  margin-right: 16px;

  a.nav-link {
    color: #fff;
    text-align: center;
    font-size: 16px;
    padding-top: 8px;
  }

  .dropdown-item {
    width: 90%;
    margin-left: 8px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .modal-right {
    width: 100%;
  }
}